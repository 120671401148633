module.exports = {
	rights: '© Tous droits réservés',
	name: 'Zifan Yao',
	job: 'Ingénieur Informatique',
	about: 'CV',
	projects: 'Projets',
	contact: 'Contact',
	gallery: 'Galerie',
	resume: 'CV',
	intro1: "Je cherche du travail dans l'informatique.",
	intro2: 'Découvrez mon profil !',
	view_more: 'En savoir plus',
	scroll_down: 'Défilez',
	madeWith: 'Fait avec ',
	hostedOn: 'et hebergé sur ',
	language: 'FR',
	fr: 'Français',
	en: 'English',
	welcome: 'Bienvenue !',
	thanks: "Merci d'avoir visité mon site web ! 💜",
	about1:
    "J'ai été diplômé à l'INSA de Lyon en Septembre 2019, spécialité Informatique et Mathématiques, après avoir réalisé mon programme d'échange académique à l'Université de Tokyo.",
	about2:
    "J'ai accumulé des expériences professionnelles en France et au Japon sous forme de stages en entreprise. J'ai également réalisé des travaux de recherche en laboratoire à l'Université de Tokyo. " +
    "Je suis confiant en mes compétences de développement logiciel, management de projets et en communication.",
	about3: 'Etant né en Chine et ayant grandi en France, je maîtrise le Français, l\'Anglais, le Chinois et le Japonais tout en continuant à apprendre l`Allemand.',
	seeResume: 'Voir mon CV',
	contactTitle: 'Faisons connaissance !',
	projectType: 'Type de projet',
	projectSkills: 'Compétences développées',
	projectLocation: 'Localisation',
	projectTime: 'Période',
	contactMe: 'Mes coordonnées',
};
