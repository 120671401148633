module.exports = {
	rights: 'All rights reserved',
	name: 'Zifan Yao',
	job: 'Software Engineer',
	about: 'Resume',
	projects: 'Projects',
	contact: 'Contact',
	gallery: 'Gallery',
	resume: 'Resume',
	intro1: 'I am currently looking for a job !',
	intro2: 'Feel free to learn about me !',
	view_more: 'View more',
	scroll_down: 'Scroll down',
	madeWith: 'Made with ',
	hostedOn: 'and hosted on ',
	language: 'EN',
	fr: 'Français',
	en: 'English',
	welcome: 'Welcome !',
	thanks: 'Thank you for visiting my website ! 💜',
	about1:
    'I graduated from INSA Lyon in September 2019 and obtained my M.E. in Software Engineering, after completing an exchange program in The University of Tokyo.',
	about2:
    'I have accumulated working experience in French and Japanese companies from internships. I achieved also academic research work in The University of Tokyo. ' +
    'I gained proven skills in software development, project management and communication.',
	about3:
    'Born in China and grew up in France, I am proficient in French, Chinese, English and Japanese. I have also basics in German.',
	seeResume: 'See my resume',
	contactTitle: "Let's get acquainted !",
	projectType: 'Project type',
	projectSkills: 'Skills involved',
	projectLocation: 'Location',
	projectTime: 'Period',
	contactMe: 'My contact informations',
};
