module.exports = {
	defaultTitle: 'Zifan Yao - Software Engineer',
	logo: 'https://zifan.fr/favicon/favicon-512.png',
	author: 'Zifan Yao',
	url: 'https://zifan.fr',
	legalName: 'Zifan Yao',
	defaultDescription: "Zifan's blog - engineer from INSA Lyon",
	socialLinks: {
		github: 'https://github.com/zuodu',
		linkedin: 'https://www.linkedin.com/in/zifan-yao',
		facebook: 'https://www.facebook.com/zifan.yao1',
		instagram: 'https://www.instagram.com/yao_zifan',
	},
	googleAnalyticsID: 'UA-147839131-1',
	themeColor: '#4391F9',
	backgroundColor: '#fbfbfb',
	social: {
		linkedin: 'zifan-yao',
		github: 'zuodu',
	},
	address: {
		city: 'Paris',
		region: 'Ile-de-France',
		country: 'France',
		zipCode: '75000',
	},
	contact: {
		email: 'contact@zifan.fr',
		phone: '0033-614516736',
	},
	foundingDate: '2019',
	recaptcha_key: '6LeXLrgUAAAAAGx23B5NbD-DYNRnSph_EMXk9dpv',
	langs: ['fr', 'en'],
	defaultLangKey: 'fr',
	resumeFileName: 'yao-cv.pdf',
};
